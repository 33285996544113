import "./style.css";
import "@aws-amplify/ui-react/styles.css";

import { Image } from "@aws-amplify/ui-react";
import PrimaryButton from "../common/Buttons/PrimaryButton";
import Card from "../common/layout/Card";
import Label from "../common/Label";
import Space from "../common/layout/Space";

interface ResultadoProps {
  resultado: ResultadoSessaoLivenessResponse;
  tryAgain: () => void;
}

const ResultadoValidacaoFacial = ({ resultado, tryAgain }: ResultadoProps) => {
  return (
    <div className="resultado-content">
      {!resultado.capturaValida ? (
        <Card style={{ width: "400px" }}>
          <Space verticalGap="md">
            <Label size="lg">
              <strong>Atenção!</strong>
            </Label>
            <Label size="md">
              A captura facial não foi aceita por não atender aos critérios
              mínimos de validação.
            </Label>

            <Image
              src={"data:image/jpeg;base64," + resultado.imagem}
              width="150px"
              height="150px"
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Foto capturada"
            />

            <Label size="sm">
              Por favor, tire sua foto em um ambiente bem iluminado, de preferência próximo a uma fonte de luz natural, e com uma parede de cor clara ao fundo para garantir a melhor qualidade de reconhecimento facial.
            </Label>
            <Label size="sm">  
              Siga as instruções contidas na tela inicial e tente novamente.
            </Label>

            <PrimaryButton onClick={tryAgain}>Tentar novamente</PrimaryButton>
          </Space>
        </Card>
      ) : (
        <Card style={{ width: "400px" }}>
          <Space verticalGap="md">
            <Label size="lg" style={{ color: "#259a47" }}>
              <strong>Sucesso!</strong>
            </Label>

            <Image
              src={"data:image/jpeg;base64," + resultado.imagem}
              width="180px"
              height="180px"
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Foto capturada"
            />

            <Label size="md">Sua validação de prova de vida foi concluída com sucesso!</Label>
            <Label size="sm">Sua proposta está em análise e você receberá as atualizações pelo canal onde você solicitou a simulação.</Label>
          </Space>
        </Card>
      )}
    </div>
  );
};

export default ResultadoValidacaoFacial;
