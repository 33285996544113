import "./style.css";
import {
  AwsCredentialProvider,
  AwsTemporaryCredentials,
  FaceLivenessDetectorCore,
} from "@aws-amplify/ui-react-liveness";
import { useEffect, useState } from "react";
import { LivenessDisplayText } from "@aws-amplify/ui-react-liveness/dist/types/components/FaceLivenessDetector/displayText";
import { Loader, ThemeProvider } from "@aws-amplify/ui-react";
import { View, Flex, Theme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  consultarResultado,
  criarSessao,
  finalizarCaptura,
} from "../../services/livenessService";
import PrimaryButton from "../common/Buttons/PrimaryButton";
import Card from "../common/layout/Card";
import Label from "../common/Label";
import Space from "../common/layout/Space";
import { Environments } from "../../environments/environments";
interface RekognitionLivenessProps {
  token: string;
  resultado: (dados: ResultadoSessaoLivenessResponse) => void;
}

const ValidacaoFacialLiveness = ({
  token,
  resultado,
}: RekognitionLivenessProps) => {
  const [exibirErro, setExibirErro] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [createLivenessApiData, setCreateLivenessApiData] = useState<{
    sessionId: string;
  } | null>();
  const [sessionToken, setSessionToken] = useState("");
  const [key, setKey] = useState("");
  const [secret, setSecret] = useState("");

  const fetchCriarSessao = () => {
    setCreateLivenessApiData(null);
    criarSessao(token)
      .then((result) => {
        setSessionToken(result.data.sessionToken);
        setKey(result.data.temporaryKey);
        setSecret(result.data.temporarySecret);
        setCreateLivenessApiData({ sessionId: result.data.idSessao });
      })
      .catch((error: any) => {
        if (error.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('Ocorreu um erro ao inicializar a validação.');
        }

        setExibirErro(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const credentialProvider: AwsCredentialProvider = async () => {
    const credentials: AwsTemporaryCredentials = {
      accessKeyId: key,
      secretAccessKey: secret,
      sessionToken: sessionToken
    };
    return credentials;
  };

  useEffect(() => {
    fetchCriarSessao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnaliseCompleta = async () => {
    if (createLivenessApiData?.sessionId) {
      consultarResultado(createLivenessApiData.sessionId).then((result) => {
        if (result.data.capturaValida) {
          finalizarCaptura(createLivenessApiData?.sessionId)
            .then(() => resultado(result.data))
            .catch((error: any) => {
              setErrorMessage(error.response.data.message);
              setExibirErro(true);
            });
        } else {
          resultado(result.data);
        }
      });
    }
  };

  const reiniciarCaptura = () => {
    setErrorMessage("");
    setExibirErro(false);
    fetchCriarSessao();
  };

  const handleLivenessError = (error: any) => {
    if (error.state === "TIMEOUT") {
      setErrorMessage("O tempo limite para tirar a foto é de 7 segundos!");
    } else if (error.state === "CAMERA_ACCESS_ERROR") {
      setErrorMessage("Ocorreu um erro ao tentar acessar a câmera!");
    } else if (error.state === "CAMERA_FRAMERATE_ERROR") {
      setErrorMessage("A câmera fornecida não atende aos requisitos mínimos para a verificação!");
    } else if (error.state === "MULTIPLE_FACES_ERROR") {
      setErrorMessage("Mais de uma face foi detectada!");
    } else if (error.state === "RUNTIME_ERROR") {
      setErrorMessage("Ocorreu um erro durante a validação facial! Detalhes: Componente de execução. Se o erro persistir, tente novamente em outro dispositivo.");
    } else if (error.state === "SERVER_ERROR") {
      setErrorMessage("Ocorreu um erro durante o streaming da imagem. Se o erro persistir, tente novamente em outro dispositivo.");
    } else if (error.state === "FACE_DISTANCE_ERROR") {
      setErrorMessage("O rosto está muito próximo da câmera antes do início da verificação.");
    } else if (error.state === "FRESHNESS_TIMEOUT") {
      setErrorMessage("Tempo esgotado para determinar a atualização do vídeo por meio de cores piscando na tela.");
    } else if (error.state === "MOBILE_LANDSCAPE_ERROR") {
      setErrorMessage("A resolução da tela não é suportada, tente novamente em outro dispositivo.");
    } else {
      setErrorMessage("Ocorreu um erro durante a validação facial! Se o erro persistir, tente novamente em outro dispositivo. Código do erro: " + error.state);
    }
    setExibirErro(true);
  };

  const dictionary: LivenessDisplayText = {
    instructionsHeaderHeadingText: "",
    instructionsHeaderBodyText:
      "Você passará por um processo de verificação facial para garantir que você seja uma pessoa real.",
    instructionListStepOneText:
      "Maximize o brilho da sua tela.",
    instructionListStepTwoText: "Escolha local bem iluminado, com uma parede clara ao fundo, para garantir a melhor qualidade de reconhecimento facial.",
    instructionListStepThreeText: "Certifique-se de não estar cobrindo seu rosto com óculos de sol ou máscaras.",
    instructionListStepFourText: "Quando uma forma oval aparecer, aproxime seu rosto até preencher todo o espaço por 7 segundos.",
    photosensitivyWarningHeadingText: "Alerta de fotossensibilidade",
    photosensitivyWarningBodyText:
      "Esta verificação exibe uma sequência de luzes coloridas. Tenha cautela se for fotossensível.",
    instructionListHeadingText:
      "Siga as instruções abaixo para completar a verificação:",
    goodFitCaptionText: "Posição correta",
    tooFarCaptionText: "Muito longe",
    instructionsBeginCheckText: "Iniciar",
    hintConnectingText: "Conectando",
    hintMoveFaceFrontOfCameraText: "Fique em frente à câmera",
    hintHoldFacePositionCountdownText: "abc",
    hintHoldFaceForFreshnessText: "Aguarde",
    hintTooFarText: "Aproxime-se da câmera",
    hintTooCloseText: "Afaste o rosto da câmera",
    hintVerifyingText: "Verificando...",
    tryAgainText: "Tentar novamente",
    serverMessageText: "Ocorreu um erro ao tentar conectar com o servidor",
    serverHeaderText: "Erro",
    timeoutHeaderText: "Tempo limite atingido",
    timeoutMessageText: "O tempo limite para tirar a foto é de 7 segundos",
    multipleFacesHeaderText: "Múltiplas faces",
    multipleFacesMessageText:
      "Certifique-se que apenas uma face seja capturada pela câmera",
    hintTooManyFacesText: "Certifique-se de que apenas um rosto esteja na frente da câmera",
    cancelLivenessCheckText: "Cancelar"
  };

  const theme: Theme = {
    name: "Cash Theme",
    tokens: {
      colors: {
        brand: {
          primary: {
            "20": "#2D9A47",
            "80": "#2D9A47",
            "90": "#2D9A47",
            "100": "#2D9A47",
          },
        },
      },
    },
  };

  return (
    <div className="content">
      {exibirErro ? (
        <Card style={{ width: "400px" }}>
          <Space verticalGap="md">
            <Label size="lg">
              <strong>Atenção</strong>
            </Label>
            <Label size="md">{errorMessage}</Label>
            <PrimaryButton onClick={reiniciarCaptura}>
              Tentar novamente
            </PrimaryButton>
          </Space>
        </Card>
      ) : (
        <>
          {loading || !createLivenessApiData?.sessionId ? (
            <Loader />
          ) : (
            <ThemeProvider theme={theme}>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
              >
                <View className="view-liveness"
                  as="div"
                >
                  <FaceLivenessDetectorCore
                    sessionId={createLivenessApiData.sessionId}
                    region={Environments.REGION}
                    onAnalysisComplete={handleAnaliseCompleta}
                    onError={(error) => {
                      handleLivenessError(error);
                    }}
                    config={{ credentialProvider }}
                    displayText={dictionary}
                    disableInstructionScreen={false}
                    
                  />
                </View>
              </Flex>
            </ThemeProvider>
          )}
        </>
      )}
    </div>
  );
};

export default ValidacaoFacialLiveness;
